export const appInfo = {
	title: 'ADOR opens',
	subtitle: 'Where Finance, Art, Technology and Expression Begins…',
	socials: [
		{
			link: 'https://twitter.com/ADORORG',
			text: 'Twitter',
			icon: ''
		},
		{
			link: 'https://discord.gg/6cZ8RNxg8F',
			text: 'Discord',
			icon: ''
		},
	]
}

export const IPFS_GATEWAY = 'https://ipfs.io/ipfs/'
// export const IPFS_GATEWAY = 'https://gateway.pinata.cloud/ipfs/';
export const ERC721_MARKETPLACE_ADDRESS = '0x065f1a66B80a259d50b1Ee2565840f602ac86f7d';
export const ERC1155_MARKETPLACE_ADDRESS = '0x0000000000000000000000000000000000000000';
export const MAX_ROYALTIES = 10;
export const ROYALTY_BASE = 10000;
export const IERC721PermitInterface = '0x5604e225';

export const defaultCollection = {
  name: 'Metador',
  slug: 'metador',
  description: 'Metador is a decentralized collection of artworks that are created and curated by the ADOR community.',
  sellerFee: 1,
  contractAddress: '0x0000000000000000000000000000000000000000',
  image: '/metador.png',
  externalUrl: '',
  creator: '0x0000000000000000000000000000000000000000',
}

export const navLinks = [
	{
		link: '/explore',
		text: 'Explore',
		icon: ''
	},
  {
		link: '/home',
		text: 'About',
		icon: ''
	}, 
  {
		link: '/transfer',
		text: 'Transfer',
		icon: ''
	},
	
]

export const fiatCurrencies = [
  {
    name: 'United States Dollar',
    iso: 'usd',
    symbol: '$',
    image: '/flags/us.svg'
  },
  {
    name: 'Euro',
    iso: 'eur',
    symbol: '€',
    image: '/flags/eu.svg'
  },
  {
    name: 'Pound Sterling',
    iso: 'gbp',
    symbol: '£',
    image: '/flags/gb.svg'
  },
  {
    name: 'Japanese Yen',
    iso: 'jpy',
    symbol: '¥',
    image: '/flags/jp.svg'
  },
  {
    name: 'Chinese Yuan',
    iso: 'cny',
    symbol: '¥',
    image: '/flags/cn.svg'
  },
  {
    name: 'Nigerian Naira',
    iso: 'ngn',
    symbol: '₦',
    image: '/flags/ng.svg'
  },
  {
    name: 'Indian Rupee',
    iso: 'inr',
    symbol: '₹',
    image: '/flags/in.svg'
  },
  {
    name: 'South African Rand',
    iso: 'zar',
    symbol: 'R',
    image: '/flags/za.svg'
  },
]

export const collectionCategories = [
  {
    name: 'Art',
    slug: 'art'
  },
  {
    name: 'Painting',
    slug: 'painting'
  },
  {
    name: 'Photography',
    slug: 'photography'
  },
  {
    name: 'Music',
    slug: 'music'
  },
  {
    name: 'Video',
    slug: 'video'
  },
  {
    name: 'Collectibles',
    slug: 'collectibles'
  },
  {
    name: 'AI',
    slug: 'ai'
  }
]

export const requestPath = (function() {
  const requestRoot = process.env.REACT_APP_REQUEST_URL;
  let paths = {
    // coin / market
    getMarketData: '/getMarketData',
    getCoinPrice: '/getCoinPrice',

    // routes that require signing
    createItem: '/token/create/',
    redeemContent: '/token/redeem/:id',
    transferToken: '/token/transfer/:id',
    createCollection: '/collection/create',
    createContract: '/contract/create',
    addToMarketplace: '/marketplace/create',
    removeFromMarketplace: '/marketplace/cancel',
    marketplaceTokenSignature: '/marketplace/signature/:marketItemId',
    finaliseSale: '/marketplace/finalise',
    createBid: '/bid/create',
    cancelBid: '/bid/cancel',
    addCurrency: '/currency/add',
    profile: '/account',
    verifyEmailToken: '/account/verifyEmailToken',
    requestEmailOTP: '/account/requestEmailOTP',
    // tokens
    recentTokens: '/api/token',
    tokenById: '/api/token/:id',
    tokensByContract: '/api/token/contract/:contract',
    tokensByOwner: '/api/token/owner/:owner',
    tokensBycollection: '/api/token/collection/:collection',
    
    // collections
    recentCollections: '/api/collection',
    collectionBySlug: '/api/collection/slug/:slug',
    collectionsByOwner: '/api/collection/owner/:owner',
    collectionsByOwnerAddress: '/api/collection/ownerAddress/:ownerAddress',
    validateCollectionSlug: '/api/collection/validate/:slug',

    // contracts
    contractBaseURI: '/api/contract/baseuri',
    contractByAddress: '/api/contract/address/:address',
    contractsByOwner: '/api/contract/owner/:owner',
    contractsByOwnerAddress: '/api/contract/ownerAddress/:ownerAddress',
    contractToken: '/api/contract/token/:address/:tokenId',

    // marketplace
    marketplaceTokens: '/api/marketplace',
    marketplaceToken: '/api/marketplace/token/:token',
    marketplaceTokensByTrader: '/api/marketplace/trader/:trader',
    
    // bids
    bidsByMarketItem: '/api/bid/marketItem/:marketItemId',
    bidByBidder: '/api/bid/bidder/:bidder', 

    // profile
    profileByAddress: '/api/account/address/:address',

    // currency
    getCurrencies: '/api/currencies',
  }

  for (let path in paths) {
    paths[path] = requestRoot + paths[path];
  }

  return paths;
})()