import React, {Suspense, lazy} from 'react';
import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useAtom } from 'jotai';
import { CTheme } from 'data/theme';
// hooks
import { useEagerConnect, useInactiveListener } from 'hooks/connector';
import { useCurrenciesList } from 'hooks/useServerData';
// Components
import { TopNav } from 'components/Navbar';
import CategoryNav from 'components/CategoryNav';
import Spinner from 'components/Spinner';
import RequireSignInIfWalletConnected from 'components/RequestAccountSignature';
import './App.scss';

// pages
const OldHome = lazy(() => import('pages/Home'));
const NewHome = lazy(() => import('pages/Home/Explore'));
const ConnectWallet = lazy(() => import('pages/ConnectWallet'));
const MarketplacePage = lazy(() => import('pages/Marketplace'));
const TokensPage = lazy(() => import('pages/Token'));
const SingleTokenPage = lazy(() => import('pages/Token/SingleItem'));
const CollectionPage = lazy(() => import('pages/Collection'));
const SingleCollectionPage = lazy(() => import('pages/Collection/SingleCollection'));
const CreatePage = lazy(() => import('pages/Create'));
const CreateCollectionPage = lazy(() => import('pages/Create/Collection'));
const CreateTokenPage = lazy(() => import('pages/Create/MintToken'));
const DeployERC721Page = lazy(() => import('pages/Create/DeployERC721'));
const DeployERC1155Page = lazy(() => import('pages/Create/DeployERC1155'));
const SupportedCurrenciesPage = lazy(() => import('pages/Currency'));
const UserProfile = lazy(() => import('pages/Profile/UpdateUserProfile'));
const AccountExplorer = lazy(() => import('pages/Profile'));
const TokenTransferPage = lazy(() => import('pages/TransferToken'));
const NotFound = lazy(() => import('pages/NotFound'));

function App() {
  const [themeClass] = useAtom(CTheme);
  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();
  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager);
  // fetch/refresh currencies list
  useCurrenciesList();


  return (
    
      <div style={{minHeight: "100vh"}} className={`App pb-5 ${themeClass}`}>
        <Toaster 
          toastOptions={{
            duration: 5000,
            className: `bg-secondary text-white fs-5 fw-bold`,
          }}
        />

        <Suspense fallback={<Spinner />}>
            <p className="bg-warning text-dark text-center fw-bold fs-5">Please use Goerli Test network (Chain Id: 5)</p>
          <TopNav />
          <CategoryNav />
          <RequireSignInIfWalletConnected>
            <Routes>
                <Route path="/" exact element={<MarketplacePage />} />
                <Route path="/marketplace" exact element={<MarketplacePage />} />
                <Route path="/marketplace/:category" exact element={<MarketplacePage />} />
                <Route path="/transfer" element={<TokenTransferPage />} />
                <Route path="/home" element={<OldHome />} />
                <Route path="/connect" element={<ConnectWallet />} />
                <Route path="/explore" element={<NewHome />} />
                <Route path="/account/update" exact element={<UserProfile />} />
                <Route path="/account/:address" exact element={<AccountExplorer />} />
                
                <Route path="/tokens" element={<TokensPage />} />
                <Route path="/tokens/:id" element={<SingleTokenPage />} />
                <Route path="/tokens/:contract/:tokenId" element={<SingleTokenPage />} />
                
                <Route path="/collections/" element={<CollectionPage />} />
                <Route path="/collections/:slug" element={<SingleCollectionPage />} />
                
                <Route path="/currency/supported" element={<SupportedCurrenciesPage />} />
                
                <Route path="/create" element={<CreatePage />} />
                <Route path="/create/collection" element={<CreateCollectionPage />} />
                <Route path="/create/token" element={<CreateTokenPage />} />
                <Route path="/create/token/:contractAddress" element={<CreateTokenPage />} />
                {/* ERC721 & ERC1155 are intentionally paged  */}
                <Route path="/deploy/ERC721" element={<DeployERC721Page />} />
                <Route path="/deploy/ERC1155" element={<DeployERC1155Page />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
          </RequireSignInIfWalletConnected>
        </Suspense>
      </div>
    
  );
}

export default App;
